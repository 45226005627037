@import "_variables.scss";
@import "./layout";
@import "./_homepage.scss";
@import "./_input.scss";
@import "./_login.scss";

.event-loading {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.7);
  > div {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
