#home {
  background: $color_background_linear;
  font-weight: 500;
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    max-width: 1000px;
    margin: 0 auto;
    .ant-row {
      align-items: center;
    }

    #contact-form {
      padding: 40px;
      background-color: $white-color;
      border-radius: 5px;
    }
  }

  div[class^="section"] {
    h1 {
      font-size: 48px;
      font-weight: 700;
      line-height: 72px;
      margin-bottom: 24px;

      span {
        color: $color_thunderbird;
      }
    }

    p {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 56px;
      color: $color_gray-1;
    }

    .image {
      width: 100%;
      height: auto;
    }

    .card-section {
      display: grid;
      grid-template-columns: repeat(2, 0.5fr);
      -moz-column-gap: 20px;
      column-gap: 20px;
      row-gap: 18px;

      .ant-card {
        background-color: $color_gray;
        border-radius: 5px;
        height: 92px;
        padding: 0 26px;
        cursor: pointer;

        &.card--black {
          background-color: $color_black-1;

          h2 {
            color: $color_white;
          }
        }

        &:hover {
          opacity: 0.8;
        }

        &-body {
          display: flex;
          align-items: center;
          padding: 0;
          height: 100%;

          img {
            margin-right: 26px;
          }

          h2,
          p {
            margin-bottom: 0;
            color: $color_gray-1;
            line-height: 30px;
          }

          h2 {
            font-size: 20px;
            font-weight: 500;
          }

          p {
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .section-1 {
    position: relative;
    width: 100%;
    height: 980px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }

    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;
      max-width: 660px;

      h1 {
        font-size: 64px;
        font-weight: 700;
        line-height: 96px;
        margin: 0;
      }

      &__sub {
        color: $color_thunderbird;
      }

      p {
        margin-top: 31px;
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }

  div[class^="section"].section-2,
  div[class^="section"].section-4 {
    padding: 87px 0 92px 0;

    .card-section {
      .ant-card {
        cursor: not-allowed;

        &:hover {
          opacity: 1;
        }
      }

      &__request-download {
        grid-column: span 2;
        width: 50%;
        margin: 0 auto;

        .ant-card {
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }

          &-body {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .section-3,
  .section-5,
  .section-7 {
    background: $color_alabaster;
    padding: 68px 0 67px 0;
  }

  .section-4 {
    padding: 45px 0 41px 0;
  }

  .section-6 {
    padding: 45px;
  }

  .section-7 {
    padding: 80px 0;
  }
}

@media (max-width: $breakpoint_extra_large_desktop_max) {
  #home {
    div[class^="section"] {
      .card-section {
        .ant-card {
          height: 80px;
          img {
            max-width: 35px;
          }

          h2 {
            font-size: 15px;
            line-height: 20px;
          }

          p {
            font-size: 10px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_large_desktop_max) {
  #home {
    div[class^="section"] {
      h1 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 14px;
      }

      p {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 24px;
      }

      .card-section {
        -moz-column-gap: 11px;
        column-gap: 11px;
        row-gap: 13px;

        .ant-card {
          height: 60px;
          padding: 0 17px;

          img {
            margin-right: 17px;
            width: 20%;
          }

          h2,
          p {
            line-height: 20px;
          }

          h2 {
            font-size: 15px;
          }

          p {
            font-size: 10px;
          }
        }
      }
    }

    .section-1 {
      height: 588px;

      .section-1__title {
        max-width: 400px;

        h1 {
          font-size: 36px;
          line-height: 36px;
        }

        p {
          margin-top: 16px;
          font-size: 13px;
          line-height: 19.5px;
        }
      }
    }

    .section-2 {
      padding: 24px 0 20px 0;
    }

    .section-3 {
      padding: 26px 0;
    }

    .section-4 {
      padding: 28px 0;
    }

    .section-6 {
      padding: 28px 0 24px 0;
    }
  }
}

@media (max-width: $breakpoint_desktop_max) {
  #home {
    div[class^="section"] {
      .card-section {
        .ant-card {
          img {
            max-width: 35px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint_tablet_max) {
  #home {
    .image-row {
      margin-top: 28px;
      order: 1;
    }

    div[class^="section"] {
      .card-section {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 11px;

        .ant-card {
          img {
            max-width: 40px;
          }

          h2,
          p {
            line-height: 15px;
          }

          h2 {
            font-size: 13px;
          }
        }
      }
    }

    div[class^="section"].section-2,
    div[class^="section"].section-4 {
      .card-section {
        &__request-download {
          grid-column: 1;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  #home {
    .section-1 {
      height: 779px;

      .section-1__title {
        width: 90%;
        h1 {
          font-size: 24px;
        }
      }
    }

    .section-2 {
      padding: 24px 0 28px 0;
    }

    .section-3 {
      padding: 20px 0;
    }

    .section-6 {
      padding: 28px;
    }
  }
  .ant-layout-header {
    padding: 0 10px !important;
  }
}

.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fc !important;

  img {
    height: 15px;
    width: auto;
    cursor: pointer;
  }

  .ant-avatar {
    vertical-align: middle;
    margin-left: 10px;
  }
}
.ant-dropdown-menu-item {
  display: flex;
  align-items: baseline;
}

section.ant-layout .ant-layout {
  height: 100vh !important;
}
