@import "./variables";

@font-face {
  font-family: RobotoRegular;
  src: url("../resources/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: RobotoBlack;
  src: url("../resources/fonts/Roboto-Black.ttf");
}

@font-face {
  font-family: RobotoBold;
  src: url("../resources/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: RobotoLight;
  src: url("../resources/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: RobotoThin;
  src: url("../resources/fonts/Roboto-Thin.ttf");
}

* {
  font-family: "Roboto", sans-serif;
}

section.ant-layout {
  background: $color_white;

  .ant-layout {
    min-height: calc(100vh - 2 * 50px);
  }

  .access__wallet {
    padding-top: 112px;
  }

  .layout__wallet--section {
    height: calc(100vh - 115px);
    min-height: auto;
    > .ant-layout-content {
      overflow-x: hidden;
    }
  }
}

.layout {
  position: relative;
  font-size: 15px;

  .layout__header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0;
    z-index: 1000;
  }
}

.ant-menu {
  a.menu-active:hover {
    color: red;
  }

  &-sub {
    .ant-menu-item:hover,
    .ant-menu-active {
      color: $color_thunderbird;
    }

    .ant-menu-item:active {
      background-color: transparent;
    }
  }

  &:not(.ant-menu-horizontal) &-sub &-item-selected {
    background-color: transparent;

    a,
    a:hover {
      color: $color_thunderbird;
    }

    &::after {
      border-right: none;
    }
  }
}

.menu__drawer {
  .ant-drawer-body {
    padding: 26px 20px;
    display: flex;
    flex-direction: column;
  }

  &--top {
    margin-bottom: 40px;
    .head {
      display: flex;
      justify-content: flex-end;

      img {
        width: 10px;
      }
    }
  }

  .ant-menu {
    &-item-selected a,
    &-item-selected a:hover {
      color: $color_thunderbird;
    }

    &-inline .ant-menu-selected::after,
    &-inline .ant-menu-item-selected::after,
    &-inline .ant-menu-item::after {
      opacity: 0;
      border-left: none;
    }

    &-inline,
    &-vertical,
    &-vertical-left {
      border-right: none;
    }

    &-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::after,
    &-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::before {
      background: $color_thunderbird;
    }

    &:not(.ant-menu-horizontal) .ant-menu-item-selected,
    &-item:active,
    &-submenu-title:active {
      background-color: transparent;
    }

    &-submenu {
      &-selected,
      &-selected &-arrow::before,
      &-selected &-arrow::after {
        color: $color_thunderbird;
      }
    }
  }
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.ant-scrolling-effect {
  width: 100% !important;
  overflow: auto !important;
}

.container-md {
  width: 100%;
  max-width: 1355px;
  padding: 26px 60px;
}

.container-modal {
  padding: 24px;
}

.container {
  width: 85%;
  max-width: 1650px;
  margin: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.container-small {
  width: 80%;
  max-width: 1419px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}

button.btn-glow--red {
  background: $color_login_title;
  border-radius: 5px;
  padding: 0 67px;
  height: 60px;
  border: none;
  color: white;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: $color_login_title;
  }

  &:hover {
    opacity: 0.95;
  }

  span {
    color: $color_white;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
  }
}

@media (max-width: $breakpoint_large_desktop_max) {
  button.btn-glow--red {
    padding: 11px 27px;

    span {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

@media (max-width: $breakpoint_desktop_max) {
  .container-md {
    padding: 40px 26px;
  }

  .menu__drawer {
    .body {
      > .body__item:last-of-type.active a {
        background-color: transparent;
        color: $color_thunderbird;
        .fill_svg {
          fill: $color_thunderbird;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .container {
    width: 100%;
  }

  .container-small {
    max-width: 334px;
  }

  button.btn-glow--red {
    span {
      line-height: 15px;
    }
  }

  .menu__drawer {
    .ant-drawer-body {
      padding: 26px 20px;
    }
    &--bottom {
      margin-left: -30px;
      margin-right: -10px;
      padding: 35px 10px 35px 30px;
    }
  }

  section.ant-layout {
    .layout__wallet--section {
      height: calc(100vh - 75px);
    }
  }
}
