input,
textarea {
  width: 100%;
  padding: 11px 18px;
  text-align: left;
  color: $color_gray-2;
  border: $border-input--gray;
  border-radius: 5px;
}

.input {
  width: 100%;
  margin-bottom: 20px;
}

.input,
.textarea {
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  color: $color_thunderbird;
}

@media (max-width: $breakpoint_large_desktop_max) {
  .input,
  .textarea {
    font-size: 12px;
    line-height: 20px;
  }
}

@media (max-width: $breakpoint_mobile_max) {
  .input {
    margin-bottom: 14px;
  }

  .textarea {
    margin-bottom: 4px;
  }
}
