.login,
.forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  width: 100vw;
  background: $color_background_linear;
  background-position: top left, bottom right;
  background-repeat: no-repeat, no-repeat;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
  }
  button.ant-btn.primary {
    color: $white-color;
    padding: 6px 10px;
    outline: none;
    border-radius: 100px;
    background-color: $color_login_title;
    font-family: $base-font-family;
    border-color: $color_grey_bold;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:hover,
    &:focus {
      color: $white-color;
      background-color: $color_login_title;
    }

    &.primary-border {
      color: $color_grey_bold;
      border-color: $color_grey_bold;
      background-color: transparent;
      &:hover,
      &:focus {
        color: $white-color;
        background-color: $color_grey_bold;
      }
    }
  }
  &__wrapper {
    text-align: center;
    max-width: 1100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    &--title,
    &--des {
      font-weight: 700;
    }
  }
  &__infor {
    text-align: left;
    &--text {
      text-align: left;
      margin-bottom: 24px;
      &-caption {
        &.ant-typography {
          font-size: 92px;
          line-height: 125px;
          font-weight: 600;
          margin-bottom: 0;
          color: $color_login_title;
        }
      }
      &-title {
        &.ant-typography {
          font-size: 24px;
          line-height: 24px;
          font-weight: 800;
          margin-bottom: 12.5px;
          color: $color_login_title;
          opacity: 0.8;
          padding-left: 10px;
        }
      }
      &-desc {
        padding-left: 10px;
        font-size: 16px;
        color: $color_login_title;
        opacity: 0.8;
      }
    }
    img {
      max-width: 100%;
    }
  }
  &__form {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    max-width: 450px;
    width: 100%;
    height: 100%;
    background-color: $white-color;

    span.link {
      margin-top: 20px;
      cursor: pointer;
      color: #1a73e8;
    }

    form {
      padding: 0px 50px 50px 50px;
    }
    &--title {
      color: $color_black-1;
      font-weight: bold;
      font-size: 21px;
      width: 100%;
      border-radius: 10px;
      background-color: $color_grey_bold;
      padding: 25px;
      margin-bottom: 30px;
    }
    &--form-title {
      &.ant-typography {
        font-weight: 600;
        color: $color_grey_bold;
        margin-bottom: 10px;
      }
      &-success {
        &.ant-typography {
          color: $color_text_caribbean-green;
          margin-top: 50px;
          margin-bottom: 20px;
        }
        img {
          height: 36px;
          width: 36px;
          padding: 5px;
          border-radius: 36px;
          background-color: $color_text_caribbean-green;
          margin-right: 12px;
        }
      }
    }
    &--form-desc {
      &.ant-typography {
        margin-bottom: 20px;
        color: #707070;
        opacity: 0.55;
        font-size: 14px;
      }
    }

    &--forgot {
      color: $orange-color-bold;
      margin-top: 20px;
      display: inline-block;
      font-size: $base-font-size * 0.75;
    }
    &--button {
      &.ant-btn {
        width: 100%;
        font-weight: 600;
        margin-top: 20px;
        height: 50px;
        position: relative;
      }

      > span {
        font-size: $base-font-size * 0.85;
      }
      .event-loading {
        position: absolute;
        border-radius: 100px;
      }
    }
  }
}

.forgot-password {
  &__form {
    &--cap.ant-typography {
      color: $orange-color-bold;
      font-weight: 700;
    }
    &--des.ant-typography {
      font-size: $base-font-size * 0.85;
      color: $gray-color-bold;
    }
    &--group {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      button.ant-btn {
        min-width: calc(50% - 7px);
      }
    }
    &--button {
      max-width: 105px;
    }
    .button {
      &__back {
        background-color: $orange-color-bold;
      }
      &__backtologin {
        max-width: none;
      }
    }
  }
}

@media only screen and (max-width: $tablet_break-point) {
  .login,
  .forgot-password {
    background-size: 100%;
    &__wrapper {
      flex-direction: column;
    }
    &__infor {
      text-align: center;
      padding: 0 40px;
      margin-bottom: 10px;
      &--text {
        display: none;
      }
    }
    &__form {
      &--title {
        padding: 15px;
      }
      form {
        padding: 0px 20px 50px 20px;
      }
    }
  }
}

.login-dot-loading {
  display: flex;
  justify-content: space-between;
  width: 40px;
  margin: 0 auto;
  align-items: center;
  height: 50px;
  .dot {
    height: 7px;
    width: 7px;
    display: inline-block;
    background: white;
    border-radius: 50px;
    -webkit-animation: dot 0.5s linear infinite alternate;
    animation: dot 0.5s linear infinite alternate;
    &:nth-of-type(1) {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    &:nth-of-type(2) {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    &:nth-of-type(3) {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }
  }
}

@-webkit-keyframes dot {
  0% {
    transform: translateY(-2.5px);
  }
  25% {
    transform: translateY(2.5px);
  }
  100% {
    transform: translateY(2.5px);
  }
}

@keyframes dot {
  0% {
    transform: translateY(-2.5px);
  }
  25% {
    transform: translateY(2.5px);
  }
  100% {
    transform: translateY(2.5px);
  }
}

.input {
  margin-bottom: 25px;

  &__wrapper {
    position: relative;
  }
  textarea {
    & + label {
      top: 5px !important;
    }
  }
  input,
  textarea {
    height: 50px;
    border-radius: 15px;
    border-width: 1px;
    border-color: #dfdfdf;
    padding: 10px 0;
    font-size: 13px;
    color: $color_gray-1;
    border: none;
    outline: none;
    border-bottom: 1px solid #dfdfdf;
    border-radius: 0;

    @-webkit-keyframes autofill {
      0%,
      100% {
        color: #666;
        background: transparent;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
      -webkit-transition-delay: 9999s;
    }

    &.control__active {
      &:focus + label,
      &:valid + label,
      & + label {
        font-size: 70%;
        top: 0;
        transform: translateY(-50%);
        z-index: 2;
        font-size: 12px;
      }
    }

    & + label {
      transition: all 0.2s;
      font-size: 14px;
      position: absolute;
      color: $gray-color-light;
      padding: 7px 0;
      top: 8px;
      left: 0;
      pointer-events: none;

      .require-field {
        color: $color_thunderbird;
        margin-left: 5px;
      }
    }
    &::-webkit-input-placeholder {
      opacity: 0.15;
      font-weight: 600;
      color: #000;
    }
    &::-moz-placeholder {
      opacity: 0.15;
      font-weight: 600;
      color: #000;
    }
    &:-ms-input-placeholder {
      opacity: 0.15;
      font-weight: 600;
      color: #000;
    }
    &::-ms-input-placeholder {
      opacity: 0.15;
      font-weight: 600;
      color: #000;
    }
    &::placeholder {
      opacity: 0.15;
      font-weight: 600;
      color: #000;
    }
  }

  textarea {
    height: auto;
  }
}
