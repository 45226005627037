// size screen responsive
// Small devices (landscape phones, 576px and up)
// Extra large devices (large desktops, 1200px and up)
// Large devices (desktops, 992px and up)
// Medium devices (tablets, 768px and up)
$breakpoint_mobile_max: 575px;
$breakpoint_tablet_max: 767px;
$breakpoint_desktop_max: 991px;
$breakpoint_large_desktop_max: 1199px;
$breakpoint_extra_large_desktop_max: 1440px;
$tablet_break-desktop: 1366px;
$tablet_break-point: 991px;
$mobile_break-point: 767px;

$base-font-size: 16px;

$color_thunderbird: #d93915;
$color_golden_grass: #dba734;
$color_alabaster: #f9f9f9;
$color_white: #ffffff;
$color_black: #111111;
$color_black-1: #000000;
$color_gray: #e9e9e9;
$color_gray-1: #6b6b6b;
$color_gray-2: #bcbcbc;
$color_gray-3: #f8f8f8;
$color_gray-4: #1f1f1f;
$color_red: #ff7a5c;
$shadow-glow--red: 0px 0px 20px #ffbfb0;
$border-input--gray: 1px solid #bdbdbd;
$color_dove_gray: #6b6b6b;
$white-color: #fff;
$black-color: #333;
$orange-color-bold: #8145f0;
$orange-color-light: #b896f6;
$orange-color-bold: #fa8244;
$orange-color-light: #fa8244b3;
$gray-color-bold: #9fafc1;
$gray-color-light: #c9d3dd;
$color_text_caribbean-green: #04d786;
$color_login_title: #3579db;
$color_grey_bold: #fff;
$color_background_linear: linear-gradient(to top, #9e9e9e, #000000);

$base-font-family: "Open Sans", sans-serif;

.text-thunderbird {
  color: $color_thunderbird;

  &:hover {
    color: $color_thunderbird;
  }
}

.text-golden-grass {
  color: $color_golden_grass;

  &:hover {
    color: $color_golden_grass;
    opacity: 0.6;
    text-decoration: underline;
  }
}

.text-underline {
  text-decoration: underline;
}
